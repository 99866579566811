@import 'main.scss';
.navbar-toggler {
    border: 2px solid #35623D!important;
    width: 50px;
    height: 47px;
    cursor: pointer;
    padding: 0;
    margin-left: 20px;
    order: 2;
    span {
        display: block;
        width: 80%;
        height: 3px;
        margin: 0 auto;
        margin-bottom: 8px;
        background-color: #35623D;
        background-image: none;
        outline: solid 1px transparent;
        transition: all .16s ease-in-out;
        transform: rotate(0) translate(0);
        &:first-child {
            margin-top: 8px;
        }
    }
    &.active {
        span {
            transform: rotate(-45deg);
            transform-origin: center;
            &:first-child {
                transform: rotate(45deg) translate(4px, -6px);
                transform-origin: left top;
            }
            &:last-child {
                transform: rotate(45deg) translate(-4px, 5px);
                transform-origin: right bottom;
            }
        }
    }
}

@media screen and (max-width:1170px) {
    .main-content {
        .container {
            >.row {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }
}

@media screen and (max-width:1140px) {
    .header {
        padding: 0;
        .container {
            width: 100%;
        }
        .contacts {
            margin-left: 20px;
        }
    }
}

//md
@media screen and (max-width:991px) {
    .header {
        .langmenu {
            order: 1;
            margin-left: auto;
        }
    }
    nav.navbar {
        padding: 10px 0;
    }
    #main-menu {
        order: 3;
    }
    .nav-item {
        .nav-link {
            float: right;
        }
    }
    .dropdown-menu {
        border: 0;
        a {
            float: right;
            width: auto;
        }
    }
}

//sm
@media screen and (max-width:767px) {
    #map {
        width: 100vw;
        margin-left: -30px;
    }
    .news-list {
        .news-item {
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width:691px) {}

//xs
@media screen and (max-width:575px) {}

@media screen and (max-width:480px) {
    section.news {
        .news-list {
            .news-item {
                img {
                    float: none;
                    width: 100%;
                    margin-bottom: 15px;
                    max-width: none;
                    max-height: none;
                }
            }
        }
    }
}