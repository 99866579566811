@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700&subset=cyrillic-ext,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=cyrillic-ext,latin-ext');
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto Slab';
}

body {
    font-family: 'Roboto';
    font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-align: center;
    font-weight: 600;
    color: #7D7D7D;
}

h3,
h4 {
    text-align: left;
}

h1 {
    margin: 30px 0;
}

.header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .navbar-brand {
        img {
            max-height: 80px;
        }
    }
    .contacts {
        background-image: url('/images/html/phone.png');
        padding-left: 70px;
        background-repeat: no-repeat;
        background-position: left center;
        margin-left: 80px;
        min-height: 80px;
        flex: 1 0 200px;
        background-color: transparent;
        span {
            display: block;
            width: 100%;
        }
        color: #898989;

        a[href*="tel:"] {
            color: #898989;
        }
    }
    .navbar-nav {
        .nav-item {
            margin-left: 25px;
            &.active {
                >a {
                    color: #35623D;
                }
            }
            a {
                color: #8d332d;
                font-weight: 600;
                font-family: 'Roboto Slab';
                &.active {
                    color: #35623D;
                }
            }
        }
    }
    .langmenu {
        margin-top: 1px;
        margin-left: 35px;
        order: 3;
        a {
            margin-right: 10px;
            color: #7d7d7d;
            position: relative;
            &.active {
                color: #35623d;
                font-weight: 500;
            }
            &:after {
                top: 3px;
                right: -8px;
                display: block;
                position: absolute;
                content: '';
                height: 14px;
                border-right: 1px solid #7d7d7d;
                padding-left: 7px;
                padding-right: 2px;
                pointer-events: none;
                cursor: pointer;
            }
            &:last-child {
                margin-right: 0;
                &:after {
                    display: none;
                }
            }
        }
    }
}

#main-menu {
    order: 1;
}

#carousel {
    width: 100%;
}

.green-text {
    background-color: #35623D;
    padding: 45px 15px;
    a.button {
        color: #ffffff;
        border-color: #ffffff;
        padding: 5px 30px;

        &:hover{
            color: #ffffff;
        }
        &.white {
            color: #35623d;
            background-color: #ffffff;
        }
    }
}

.formatedtext {
    table {
        th,
        td {
            padding: 5px 15px;
        }
    }
    img {
        max-width: 100%;
    }
    hr {
        border-top: 3px solid #cacaca;
    }
    a {
        color: #0069A6;
        &:hover {
            color: #0069A6;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: center;
        margin-bottom: 0.8em;
    }
}

.main-content {
    .row {
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 30px;
        border-top: 3px solid #cacaca;
        padding-top: 50px;
        margin-bottom: 50px;

        &:first-of-type{
            border-top: 0;
            padding-top: 0;
        }
    }

    .col-12 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .formatedtext {
        p,
        li {
            line-height: 2em;
        }
        h2 {
            margin-bottom: 30px;
        }
        h3 {
            margin-bottom: 20px;
        }
        ul {
            padding-left: 0;
            margin-left: 0;
            list-style: none;
            >li {
                margin-bottom: 15px;
                margin-left: 15px;
                text-indent: -15px;
                &:before {
                    content: '\2022';
                    font-size: 30px;
                    position: relative;
                    top: 5px;
                    display: inline-block;
                    width: 15px;
                    text-indent: 0;
                    color: #35623d;
                    line-height: 1em;
                }
            }
        }
        ol {
            padding-left: 0;
            margin-left: 0;
            list-style: none;
            counter-reset: cnt;
            >li {
                margin-bottom: 15px;
                counter-increment: cnt;
                margin-left: 25px;
                text-indent: -25px;
                &:before {
                    content: counter(cnt)'.';
                    position: relative;
                    display: inline-block;
                    width: 25px;
                    font-weight: 600;
                    text-indent: 0;
                    color: #35623d;
                    line-height: 1em;
                }
            }
        }
        blockquote{
            background-color: #F3F3F3;
            color: #848484;
            font-style: italic;
            padding: 15px;
            &::after{
                display: block;
                content: '';
                clear: both;
            }

            p{
                line-height:1.5em;
            }
        }
    }

    .col-12 {
        margin-bottom: 20px;
    }
}

section.news {
    padding: 45px 0 50px 0;
    .news-list {
        margin-top: 30px;
        img {
            margin-right: 30px;
            max-width: 115px;
            max-height: 115px;
        }
        h4 {
            color: #8d332d;
            font-weight: 600;
        }
        .date {
            font-family: 'Roboto Slab';
            color: #7d7d7d;
        }
    }
    .news-item {
        >a {
            text-decoration: none;
        }
        &:hover {
            h4 {
                text-decoration: underline;
            }
        }
    }
    a.button {
        margin-top: 30px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

#map {
    position: relative;
    min-height: 350px;
    height: 100%;
    width: 50vw;
    margin-left: -9px;
}

.footer {
    background-color: #424242;
    color: #858585;
    font-size: 14px;
    padding: 30px;
    .copyright {
        text-align: center;
        p:last-child {
            margin-bottom: 0;
        }
    }
}

.container {
    width: 100%;
    margin: 0 auto;
    max-width: 1140px;
}

.contacts {
    background-color: #F3F3F3;
    .contact-wrap {
        padding: 45px 0;
        .formatedtext {
            table {
                margin: 0 auto;
                td {
                    width: 50%;
                    padding: 2px 15px;
                }
            }
        }
    }
    a {
        font-weight: 600;
        &[href*="tel:"] {
            color: #000000;
        }
    }
    .button {
        margin-top: 30px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-color: #35623D;
        color: #ffffff;
    }
    &.full {
        background-color: transparent;
        >.container {
            margin-bottom: 60px;
        }
        .contact-wrap {
            padding: 0;
        }
        .formatedtext{
            h4{
                text-align: left;
            }
        }
        h4 {
            margin-bottom: 15px;
        }
        #map {
            width: 100%;
            height: 500px;
            margin-left: 0;
        }
    }
}

a {
    color: #35623D;
    transition: all .16s ease-in-out;
    &:hover {
        color: #35623D;
    }
}
.button {
    text-decoration: none;
    display: inline-block;
    border: 2px solid #35623D;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    &:hover {
        box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.3);
    }
}

.dropdown-menu {
    .dropdown-item {
        &.active,
        &:active {
            background-color: transparent;
        }
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
}

.graphicaleditdiv {
    min-height: 0!important;
}

p {
    margin-bottom: 5px
}

#ContactFormWrap {
    .col-12 {
        margin-bottom: 15px;
    }
}

input:not([type="submit"]),
textarea {
    width: 100%;
    font-family: "Roboto";
    padding: 5px 15px;
    border: 3px solid #D6D6D6;
}

textarea {
    height: 105px;
}

input[type="submit"] {
    font-family: "Roboto";
    width: 100%;
    max-width: 215px;
    appearance: none;
    background-color: #35623D;
    border: 0;
    color: #ffffff;
    font-weight: 500;
    padding: 5px 10px;
}
a[href*="mailto"]{
    color: #35623D;
    &:hover{
        color: #35623D;
    }
}

.g-recaptcha{
    transform: scale(0.9);
    transform-origin: left bottom;
}

input[type="submit"]{
    cursor: pointer;
}
textarea.error,
input.error{
    border:solid 3px #a01919!important;
}
.g-recaptcha.error>div{
    outline:solid 3px #a01919!important;
}